
.StoreMarketingOne{
    .StoreMarketingOneForm{
        .storePromotion{
            margin-top: 28px;
            padding-left: 13px;
            .storePromotionTitle{
                font-size: 14px;
                .title{
                    color: #13131B;
                }
                .message{
                    color: #878994;
                }
            }
            .storePromotionUpload{
                margin-top: 18px;
                display: flex;
                align-items: center;
                .uploadBtn{
                    background: #1122D8;
                    color: #ffffff;
                    width: 80px;
                    height: 30px;
                    font-size: 12px;
                    border-radius: 2px;
                    text-align: center;
                    line-height: 30px;
                    cursor: pointer;
                }
                .uploadFile{
                    font-size: 12px;
                    color: #13131B;
                    font-weight: 400;
                    margin-left: 20px;
                    .fileIcon{
                        color: #FF0000;
                        font-size: 14px;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
            }
        }
        .toBtn{
            display: flex;
            justify-content: center;
            margin-top: 40px;
            .btn1{
                width: 100px;
                height: 30px;
                line-height: 0;
            }
            .btn1:nth-child(1){
                background: #ffffff;
                border-color: #1122D8;
                color: #1122D8;
            }
            .btn1:nth-child(2){
                background: #1122D8;
                border-color: #1122D8;
                color: #ffffff;
            }
        }
    }
}
